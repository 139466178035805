<template>
  <div class="bill-root">
    <div class="query-container">
      <el-date-picker v-model="startDate" value-format="yyyy-MM-dd" type="date" placeholder="选择起始日期">
      </el-date-picker>
      至
      <el-date-picker v-model="endDate" type="date" value-format="yyyy-MM-dd" placeholder="选择截至日期">
      </el-date-picker>
      <el-select v-model="status" clearable placeholder="请选择" style="margin-left: 10px;">
        <el-option v-for="item in statusOpts" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>

      <el-input v-model="keyword" placeholder="司机编号或车牌号" clearable style="width: 200px;margin-left: 10px;"></el-input>
      <el-button type="primary" style="margin-left: 10px" @click="query">查询</el-button>
    </div>

    <el-table :data="list" border style="width: 100%">
      <el-table-column label="时间" prop="serviceDate" width="160"></el-table-column>
      <el-table-column label="服务商" prop="providerName"> </el-table-column>
      <el-table-column label="服务类型">
        <template slot-scope="scope">
          {{ transeferIdNames(scope.row.serviceType) }}
        </template>
      </el-table-column>
      <el-table-column prop="staffName" label="检修员"> </el-table-column>
      <el-table-column prop="vehicleNo" label="车牌号"></el-table-column>
      <el-table-column prop="driverNo" label="司机编号"></el-table-column>
      <el-table-column prop="driverName" label="司机"></el-table-column>
      <el-table-column label="费用（元）">
        <template slot-scope="scope">
          {{ scope.row.amount ? scope.row.amount / 100 : 0 }}
        </template>
      </el-table-column>
      <el-table-column label="服务状态">
        <template slot-scope="scope">
          {{ getBillStatusLabel(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column prop="miles" label="里程数"> </el-table-column>
      <el-table-column label="审核状态">
        <template slot-scope="scope">
          {{ getBillAuditLabel(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
      <el-table-column prop="rejectReason" label="驳回原因"></el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-link type="primary" v-if="scope.row.status == 0" @click="serviceBill(scope.row)"
            style="margin-right:10px;">送检</el-link>
          <el-link type="primary" v-if="scope.row.status == 0" @click="modifyBill(scope.row)"
            style="margin-right:10px;">修改</el-link>
          <el-link type="primary" v-if="scope.row.status == 0" @click="deleteBill(scope.row)"
            style="margin-right:10px;">删除</el-link>
          <el-link type="primary" @click="viewBill(scope.row)">详情</el-link>
        </template>
      </el-table-column>
    </el-table>

    <!-- 侧边栏 -->
    <el-dialog title="单据详情" :visible.sync="showBillDialog">
      <billDetail :bill-id="currentBill.id" v-if="!!currentBill"></billDetail>

      <!-- 弹出框 -->
      <el-dialog title="驳回原因" width="30%" :visible.sync="showRemarkDialog" append-to-body>
        <el-input v-model="remark" placeholder="请输入驳回原因"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showRemarkDialog = false">取消</el-button>
          <el-button @click="doAuditBillNotPass">确定</el-button>
        </span>
      </el-dialog>

      <span slot="footer" class="dialog-footer">
        <el-button v-if="currentBill && (currentBill.status == 10 || currentBill.status == 20)" type="primary"
          @click="auditBillPass(currentBill)">通过</el-button>
        <el-button v-if="currentBill && (currentBill.status == 10 || currentBill.status == 20)" type="danger"
          @click="auditBillNotPass(currentBill)">不通过</el-button>
        <el-button @click="(showBillDialog = false), (currentBill = null)">关闭</el-button>
      </span>
    </el-dialog>


    <el-dialog title="修改信息" :visible.sync="formVisible" width="600px" max-height="700" :size="size"
      :close-on-click-modal="false">
      <el-form ref="form" :model="form" :size="size" label-width="140px" inline>
        <el-form-item label="车牌号：" prop="vehicleNo" clearable>
          <el-input v-model="form.vehicleNo" disabled></el-input>
        </el-form-item>
        <el-form-item label="司机：" prop="driverName" clearable>
          <el-input v-model="form.driverName" disabled></el-input>
        </el-form-item>
        <el-form-item label="司机编号：" prop="driverNo" clearable>
          <el-input v-model="form.driverNo" disabled></el-input>
        </el-form-item>

        <el-form-item label="时间：" prop="billDate">
          <el-date-picker style="width: 200px" v-model="form.billDate" type="date" clearable
            value-format="yyyy-MM-dd hh:mm" placeholder="选择日期"></el-date-picker>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="modifyDate">保 存</el-button>
        <el-button type="info" :size="size" @click="formVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { listServiceBill, toService, auditBillStatus, getServiceScopes, deleteBillInfo, changeProviderBillDate } from "@/api/partner_admin";
import { DateUtil } from "@/util";
import billDetail from "../bill_detail";
export default {
  name: "serviceList",
  components: {
    billDetail,
  },
  data() {
    return {
      serviceScopeObjs: [],
      statusOpts: [
        { label: "待办", value: "0" },
        { label: "待审", value: "1" },
        { label: "未通过", value: "2" },
        { label: "已通过", value: "3" },
      ],

      list: [],
      remark: null,
      showBillDialog: false,
      showRemarkDialog: false,
      currentBill: null,
      startDate: null,
      endDate: null,
      status: null,
      keyword: '',
      formVisible: false,
      form: {},
      size: "small",
    };
  },
  mounted() {
    this.initPage();
    this.query();
  },
  methods: {
    initPage() {
      this.endDate = DateUtil.getDayStr(15);
      this.startDate = DateUtil.format(new Date(), "yyyy-MM") + "-01";
      getServiceScopes().then((res) => {
        this.serviceScopeObjs = res.data || [];
      });
    },
    query() {
      listServiceBill(null, this.startDate, this.endDate, this.status, this.keyword).then((res) => {
        this.list = res.data || [];
      });
    },
    transeferIdNames(serviceType) {
      if (!serviceType) {
        return "";
      }

      for (var i = 0; i < this.serviceScopeObjs.length; i++) {
        if (this.serviceScopeObjs[i].value == serviceType) {
          return this.serviceScopeObjs[i].label;
        }
      }
      return "";
    },
    getBillStatusLabel(bill) {
      return bill.status < 10 ? "待办" : "已办";
    },
    getBillAuditLabel(bill) {
      if (bill.status < 10) {
        return "-";
      } else if (bill.status == 31) {
        return "不通过";
      } else if (bill.status == 30) {
        return "通过";
      } else {
        return "待审";
      }
    },
    viewBill(bill) {
      this.showBillDialog = true;
      this.currentBill = bill;
    },
    auditBillPass(bill) {
      this.$confirm("确定要通过该单据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        auditBillStatus([bill.id], bill.providerId, 30).then(() => {
          bill.status = 30;
          this.showBillDialog = false;
          this.currentBill = null;
        });
      });
    },
    auditBillNotPass() {
      this.showRemarkDialog = true;
      this.remark = null;
    },
    doAuditBillNotPass() {
      let me = this;
      let bill = this.currentBill;
      let rejectReason = this.remark;
      auditBillStatus([bill.id], bill.providerId, 31, rejectReason).then(() => {
        bill.status = 31;
        me.showRemarkDialog = false;
        me.showBillDialog = false;
        me.currentBill = null;
      });
    },


    modifyDate() {
      const param = {
        id: this.form.id,
        billDate: this.form.billDate,
      };
      changeProviderBillDate(param).then(() => {
        this.formVisible = false;
        this.$message.success("修改成功");
        this.query();
      });
    },

    serviceBill(bill) {
      this.$confirm("确定要送检吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        toService(bill.id).then(() => {
          bill.status = 2;
        });
      });
    },

    modifyBill(bill) {
      this.form = { ...bill };
      this.formVisible = true;
    },

    deleteBill(bill) {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteBillInfo(bill.id).then(() => {
          let idx = -1;
          for (var i = 0; i < this.list.length; i++) {
            if (this.list[i].id == bill.id) {
              idx = i;
              break;
            }
          }
          this.list.splice(idx, 1);
        });
      });
    },
  },
};
</script>

<style scoped>
.bill-root {
  padding: 10px;
}

.query-container {
  padding-bottom: 20px;
}
</style>
